import {
  createNodeRenderer,
  createRenderer,
} from "@resource/atlas/content-editor/renderer";
import { createVariableNodeRenderer } from "@resource/atlas/content-editor/variables";
import {
  SerializedParagraphNode,
  SerializedRootNode,
  SerializedTextNode,
} from "lexical";

import { interviewRendererSet } from "./variables";

const renderRoot = createNodeRenderer<SerializedRootNode>((_, children) => (
  <div>{children}</div>
));

const renderParagraph = createNodeRenderer<SerializedParagraphNode>(
  (_, children) => <>{children}</>
);
const renderText = createNodeRenderer<SerializedTextNode>(({ text }) => {
  return <>{text}</>;
});

// Simplified renderer that effectively renders the title as plain text so the parent can style it
export const renderInterviewTitle = createRenderer({
  nodeRenderers: {
    root: renderRoot,
    paragraph: renderParagraph,
    text: renderText,
    variable: createVariableNodeRenderer(interviewRendererSet),
  },
});
