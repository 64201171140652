import { convert } from "html-to-text";
import DOMPurify from "isomorphic-dompurify";
import { useMemo } from "react";

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, "children"> {
  html: string;
  isTextPreview?: boolean;
  allowedTags?: DOMPurify.Config["ALLOWED_TAGS"];
}

function SafeHtml({
  html,
  allowedTags,
  isTextPreview = false,
  ...restProps
}: Props) {
  const safeHtml = useMemo(() => {
    const sanitized = DOMPurify.sanitize(html, {
      ALLOWED_TAGS: [
        ...(allowedTags ?? []),
        ...(isTextPreview ? ["br", "li", "ul", "ol"] : []),
      ],
    });

    if (typeof window !== "undefined" && isTextPreview) {
      return convert(sanitized, { preserveNewlines: false });
    }
    return sanitized;
  }, [html, allowedTags, isTextPreview]);

  return (
    <div
      {...restProps}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: safeHtml,
      }}
    />
  );
}

export default SafeHtml;
