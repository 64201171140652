/**
 * Component responsible for rendering an interview title. There are 3 possibilities for titles;
 * 1. Plain text
 * 2. Slate (HTML with variables to be replaced)
 * 3. Lexical state
 *
 * This component hides away the complexity of rendering these.
 */

import { AtlasContentEditorSerializedState } from "@resource/atlas";
import { useRender } from "@resource/atlas/content-editor/renderer";
import { VariableRendererProvider } from "@resource/atlas/content-editor/variables";
import {
  AllowedInterviewTemplateTitleTags,
  replacePlaceholderValues,
} from "@resource/common";
import SafeHtml from "components/Generic/SafeHtml";
import { ComponentPropsWithoutRef, useMemo } from "react";
import { useInterviewValueSet } from "shared/guide-content/interview-title/variables";

import { renderInterviewTitle } from "../../../../../shared/guide-content/interview-title/renderer";

type Interview = {
  replacementData: GraphQL_JSON;
  title: string;
};

export type InterviewTitleProps = ComponentPropsWithoutRef<"div"> & {
  interview: Interview;
};

/**
 * Legacy (slate) title renderer
 */
function LegacyTitle({
  interview,
  ...props
}: Omit<InterviewTitleProps, "valueSet">) {
  const title = useMemo(
    () => replacePlaceholderValues(interview.title, interview.replacementData),
    [interview]
  );

  return (
    <SafeHtml
      html={title}
      allowedTags={AllowedInterviewTemplateTitleTags}
      {...props}
    />
  );
}

/**
 * Lexical title renderer
 */
function LexicalTitle({ interview, ...props }: InterviewTitleProps) {
  const lexicalData = useMemo(() => {
    return JSON.parse(interview.title) as AtlasContentEditorSerializedState;
  }, [interview.title]);

  const renderedContent = useRender(renderInterviewTitle, lexicalData);

  const valueSet = useInterviewValueSet({
    replacementData: interview.replacementData,
  });

  return (
    <VariableRendererProvider valueSet={valueSet}>
      <div {...props}>{renderedContent}</div>
    </VariableRendererProvider>
  );
}

function isLexicalContent(title: string) {
  try {
    JSON.parse(title);
    return true;
  } catch (err) {
    return false;
  }
}

export function InterviewTitle({ interview, ...props }: InterviewTitleProps) {
  const isLexical = isLexicalContent(interview.title);

  return isLexical ? (
    <LexicalTitle interview={interview} {...props} />
  ) : (
    <LegacyTitle interview={interview} {...props} />
  );
}
