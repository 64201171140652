/* eslint-disable no-underscore-dangle, import/prefer-default-export */
import type { SerializedEditorState } from "lexical";
import { useEffect, useState } from "react";

import { useEvent } from "../../../__utils/react";

export function useRender<T>(
  renderer: (
    state: SerializedEditorState,
    renderId?: string
  ) => Promise<T | null>,
  state?: SerializedEditorState,
  onError?: (error: Error) => void
) {
  const [result, setResult] = useState<T | null>(null);

  const _onError = useEvent((error: Error) => {
    if (onError) onError(error);
  });

  useEffect(() => {
    async function render() {
      if (state) {
        try {
          setResult(await renderer(state));
        } catch (error) {
          _onError(error);
        }
      }
    }
    render();
  }, [_onError, renderer, state]);

  return result;
}
