/* eslint-disable no-console, @typescript-eslint/no-explicit-any */
import { createNodeRenderer } from "../../renderer";
import type { VariableRenderer, VariableRendererSet } from "./types";
import { SerializedUnknownVariableNode } from "./UnknownVariableNode";
import { useVariableRendererContext } from "./variable-renderer-context";
import type { SerializedVariableNode } from "./VariableNode";

// node renderer
// -------------

type VariableProps = {
  renderVariable: VariableRenderer<any, any>;
  id: string;
  value: unknown;
  config: unknown;
};

function Variable({
  renderVariable,
  id,
  value: nodeValue,
  config,
}: VariableProps) {
  const { valueSet = {} } = useVariableRendererContext() ?? {};
  const contextValue = valueSet[id];
  const value = contextValue ?? nodeValue;

  if (value === undefined || value === "") {
    console.warn(`Missing value for variable with id "${id}"`);
    return null;
  }

  return <>{renderVariable({ value, config })}</>;
}

export const createVariableNodeRenderer = (
  rendererSet: VariableRendererSet<any>,
  { asText }: { asText?: boolean } = {}
) =>
  createNodeRenderer<SerializedVariableNode>(({ value, config, id }) => {
    const renderVariable = rendererSet[id];

    if (asText) return <>{renderVariable({ value, config }, { asText })}</>;

    return (
      <Variable
        renderVariable={renderVariable}
        id={id}
        value={value}
        config={config}
      />
    );
  });

export const createUnknownVariableNodeRenderer = () =>
  createNodeRenderer<SerializedUnknownVariableNode>(({ value }) => (
    <>{value.variableId}</>
  ));
