/* eslint-disable import/prefer-default-export */
import { useHovercardState as useAriakitHovercardState } from "ariakit";

import { SHARED_POPOVER_STATE_DEFAULTS } from "../__utils/atlas";
import { createDefaultProps } from "../__utils/react";
import type { AtlasHovercardState, AtlasHovercardStateProps } from "./types";

const DEFAULT_PROPS = createDefaultProps<AtlasHovercardStateProps>()(
  SHARED_POPOVER_STATE_DEFAULTS
);

/**
 * Creates a hovercard state.
 *
 * @example
 * const hovercard = useHovercardState();
 * return <Hovercard.Root state={hovercard}>...</Hovercard.Root>;
 */
export function useHovercardState({
  placement = DEFAULT_PROPS.placement,
  animated = DEFAULT_PROPS.animated,
  gutter = DEFAULT_PROPS.gutter,
  overflowPadding = DEFAULT_PROPS.overflowPadding,
  ...props
}: AtlasHovercardStateProps = {}) {
  const hovercard: AtlasHovercardState = useAriakitHovercardState({
    placement,
    animated,
    gutter,
    overflowPadding,
    ...props,
  });
  return hovercard;
}
