/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, ReactNode, useContext, useMemo } from "react";

import type {
  ValueSet,
  VariableNameSet,
  VariableSet as _VariableSet,
} from "./types";

type VariableRendererContextValue<VariableSet extends _VariableSet> = {
  valueSet?: ValueSet<VariableSet>;
  nameSet?: VariableNameSet<VariableSet>;
};

const VariableRendererContext = createContext<
  VariableRendererContextValue<any> | undefined
>(undefined);

type ContentEditorVariableRendererProviderProps<
  VariableSet extends _VariableSet
> = {
  valueSet?: ValueSet<VariableSet>;
  nameSet?: VariableNameSet<VariableSet>;
  children?: ReactNode;
};

export function VariableRendererProvider<VariableSet extends _VariableSet>({
  valueSet,
  nameSet,
  children,
}: ContentEditorVariableRendererProviderProps<VariableSet>) {
  const value = useMemo(() => ({ valueSet, nameSet }), [nameSet, valueSet]);

  return (
    <VariableRendererContext.Provider value={value}>
      {children}
    </VariableRendererContext.Provider>
  );
}

export function useVariableRendererContext<VariableSet extends _VariableSet>(
  required: true
): VariableRendererContextValue<VariableSet>;
export function useVariableRendererContext<VariableSet extends _VariableSet>(
  required?: false
): VariableRendererContextValue<VariableSet> | undefined;
export function useVariableRendererContext<VariableSet extends _VariableSet>(
  required?: boolean
): VariableRendererContextValue<VariableSet> | undefined {
  const value = useContext(VariableRendererContext);
  if (required && !value)
    throw new Error(
      "Missing context for rendered variables, did you forget to use VariableRendererProvider?"
    );
  return value;
}
