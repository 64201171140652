import { getAttr, h, transform, withAttr, withClass } from "html-ast-transform";
import { decode } from "html-entities";
import DOMPurify from "isomorphic-dompurify";
import _ from "lodash";
import moment from "moment-timezone";
import parse5 from "parse5";

import { getEventDateGroups } from "./event-date-groups";
import { joinNames, joinWithFinalSeparator } from "./strings";

// Override some Moment Timezone Abbreviations
//
const zone = moment.tz.zone("Asia/Dubai");
if (zone) {
  zone.abbrs[0] = "GST";
}

export interface Placeholder {
  readonly id: string;
  readonly label: string;
}

// The set of variables that should be available anywhere
type GuidePlaceholderIDs = {
  "candidate-first-name": string;
  "candidate-last-name": string;
  "candidate-full-name": string;
  "recruiter-first-name": string;
  "recruiter-last-name": string;
  "recruiter-full-name": string;
  "coordinator-first-name": string;
  "coordinator-last-name": string;
  "coordinator-full-name": string;
  "company-name": string;
  "job-role-name": string;
  "greenhouse-job-link": string;
};

export type EventPlaceholderIDs = GuidePlaceholderIDs & {
  "all-interviewers-full-names": string;
  "all-interviewers-first-names": string;
  "first-interviewer-first-name": string;
  "first-interviewer-last-name": string;
  "first-interviewer-full-name": string;
  "first-interviewer-title": string;
};

export type EmailPlaceholderIDs = EventPlaceholderIDs & {
  "sender-name": string;
  "sender-first-name": string;
  "next-interview-date": string;
  "next-interview-time": string;
  "upcoming-interview-date": string;
  "upcoming-interview-time": string;
  "remaining-interview-datetime-range": string;
  "guide-link": string;
  "scheduler-link": string;
  "remaining-interview-panel": string;
  "remaining-interview-panel-with-interviewers": string;
  "remaining-interview-panel-with-interviewers-full-names": string;
  office: string;
};

export type StagePlaceholderIDs = GuidePlaceholderIDs & Record<string, string>;

export type StagePlaceholder = Placeholder & {
  id: keyof StagePlaceholderIDs;
  label: string;
};

export type EmailPlaceholder = Placeholder & {
  id: keyof EmailPlaceholderIDs;
  label: string;
};

const GUIDE_PLACEHOLDERS: Placeholder[] = [
  { id: "candidate-first-name", label: "Candidate First Name" },
  { id: "candidate-full-name", label: "Candidate Full Name" },
  { id: "company-name", label: "Company" },
  { id: "job-role-name", label: "Job Role" },
  { id: "recruiter-first-name", label: "Recruiter First Name" },
  { id: "recruiter-full-name", label: "Recruiter Full Name" },
  { id: "coordinator-first-name", label: "Coordinator First Name" },
  { id: "coordinator-full-name", label: "Coordinator Full Name" },
  { id: "greenhouse-job-link", label: "Greenhouse Job Link" },
];

export const EVENT_PLACEHOLDERS: Placeholder[] = _.unionBy(
  GUIDE_PLACEHOLDERS,
  [
    { id: "all-interviewers-full-names", label: "All Interviewer Full Names" },
    {
      id: "all-interviewers-first-names",
      label: "All Interviewer First Names",
    },
    {
      id: "first-interviewer-first-name",
      label: "First Interviewer First Name",
    },
    { id: "first-interviewer-full-name", label: "First Interviewer Full Name" },
    { id: "first-interviewer-title", label: "First Interviewer Job Title" },
  ],
  "id"
);

export const EMAIL_PLACEHOLDERS: Placeholder[] = _.unionBy(
  EVENT_PLACEHOLDERS,
  [
    { id: "next-interview-date", label: "Next Interview Date" },
    { id: "next-interview-time", label: "Next Interview Time" },
    { id: "upcoming-interview-date", label: "Upcoming Interview Date" },
    { id: "upcoming-interview-time", label: "Upcoming Interview Time" },
    {
      id: "remaining-interview-datetime-range",
      label: "Remaining Interviews Time Range",
    },
    {
      id: "remaining-interview-panel",
      label: "Interview Panel",
    },
    {
      id: "remaining-interview-panel-with-interviewers",
      label: "Interview Panel with Interviewers",
    },
    {
      id: "remaining-interview-panel-with-interviewers-full-names",
      label: "Interview Panel with Interviewers (Full Names)",
    },
    { id: "guide-link", label: "Guide Link" },
    { id: "scheduler-link", label: "Interview Scheduler Link" },
    { id: "sender-name", label: "Sender Full Name" },
    { id: "sender-first-name", label: "Sender First Name" },
    { id: "office", label: "Office" },
  ],
  "id"
);

export const STAGE_PLACEHOLDERS: Placeholder[] = GUIDE_PLACEHOLDERS;

export type GuidePlaceholderData = {
  candidateFirstName: string;
  candidateLastName: string;
  candidateFullName: string;
  companyName: string;
  jobRoleName: string;
  recruiterFirstName: string;
  recruiterLastName: string;
  recruiterFullName: string;
  recruiterEmailAddress: string;
  coordinatorFirstName: string;
  coordinatorLastName: string;
  coordinatorFullName: string;
  coordinatorEmailAddress: string;
  guideUrl: string;
  greenhouseJobUrl: string;
};

export type StagePlaceholderData = GuidePlaceholderData & {
  stageCustomVariables: Record<string, string>;
};

type PartialInterviewer = {
  title: string;
  firstName: string;
  lastName: string;
};

export type PartialEvent = {
  startTime: string;
  endTime: string;
  title: string;
  hidden: boolean;
  interviewers: PartialInterviewer[];
};

export type EventPlaceholderData = GuidePlaceholderData & {
  interviewers: PartialInterviewer[];
};

export type EmailPlaceholderData = StagePlaceholderData & {
  allInterviewers: PartialInterviewer[];
  senderName: string;
  senderFirstName: string;
  sortedVisibleUpcomingEvents: PartialEvent[];
  timezone: string;
  office: string;
};

export type AllPlaceholderIDs = EmailPlaceholderIDs &
  EventPlaceholderIDs &
  StagePlaceholderIDs;

export type EventPlaceholder = Placeholder & {
  id: keyof EventPlaceholderIDs;
  label: string;
};

type ReplaceValueOptions = {
  throwOnMissing: boolean;
};

type Replacements = Partial<AllPlaceholderIDs>;

const renderCustomVariableReplacements = (
  stageCustomVariables: Record<string, string>
) => {
  const urlSuffix = ".Url";
  const customVariableKeys = Object.keys(stageCustomVariables);
  return _.reduce(
    customVariableKeys,
    (acc, next) => {
      let renderedValue = stageCustomVariables[next];

      if (next.endsWith(urlSuffix)) {
        const [prefix] = _.split(next, urlSuffix);
        const matchingKeyExists = _.includes(
          customVariableKeys,
          `${prefix}.Label`
        );
        if (matchingKeyExists) {
          renderedValue = `<a href="${renderedValue}" target="_blank" rel="noopener noreferrer">${
            stageCustomVariables[`${prefix}.Label`]
          }</a>`;
        } else {
          renderedValue = `<a href="${renderedValue}" target="_blank" rel="noopener noreferrer">${renderedValue}</a>`;
        }
      }

      return {
        ...acc,
        [next]: renderedValue,
      };
    },
    {}
  );
};

export const replaceValues = (
  value: string,
  replacements: Replacements,
  options?: Partial<ReplaceValueOptions>
): string =>
  decode(
    transform(value, {
      replaceTags: {
        span: (node: parse5.Node) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const tag = getAttr(node, "data-id");
          if (tag) {
            const tagValue = (replacements as Record<string, unknown>)[tag] as
              | string
              | undefined;
            if (tagValue) {
              return h("#text", tagValue);
            }
            if (options?.throwOnMissing) {
              throw new Error(`Missing tag ${tag}`);
            } else {
              return h("#text", "");
            }
          }
          return node;
        },
      },
      trimWhitespace: false,
    })
  );

export const replaceLabels = (
  value: string,
  replacements: Replacements,
  throwOnMissing = false
): string =>
  transform(value, {
    replaceTags: {
      span: (node: parse5.Node) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const tag = getAttr(node, "data-id");
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const tagLabel = getAttr(node, "data-label");

        if (tag) {
          const tagValue = (replacements as Record<string, unknown>)[tag] as
            | string
            | undefined;
          if (tagValue) {
            const tagValueNode = h(tagValue);
            const childNodes: parse5.Node[] = [];
            let label = tagValue;
            // Support the case where we replacing the label with HTML content, like a link
            if (
              "tagName" in tagValueNode &&
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (tagValueNode as parse5.DefaultTreeNode).tagName
            ) {
              const firstTextNode = _.find(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (tagValueNode as parse5.Node).childNodes,
                {
                  nodeName: "#text",
                }
              );
              label = firstTextNode?.value || tagValue;
              childNodes.push(firstTextNode);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const n = withAttr(node, "data-label", label);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (n as parse5.Node).childNodes = childNodes;
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              const variable = withClass(
                tagValueNode,
                "atlas-VariableToken variant-read"
              );
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (variable as parse5.Node).childNodes = [n];
              return variable;
            }
            const child = h("#text", tagValue);
            childNodes.push(child);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const n = withAttr(node, "data-label", label);
            const variable = withClass(n, "atlas-VariableToken variant-read");
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (variable as parse5.Node).childNodes = childNodes;
            return variable;
          }
          if (throwOnMissing) {
            throw new Error(`Missing tag ${tag}`);
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!node.childNodes.length && tagLabel) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            node.childNodes.push(h("#text", tagLabel));
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const variable = withClass(
            node,
            "atlas-VariableToken variant-read is-undefined"
          );
          return variable;
        }
        return node;
      },
    },
    trimWhitespace: false,
  });

type BuildInterviewPanelProps = {
  sortedVisibleEvents: PartialEvent[];
  timezone: string;
  useTimeRanges?: boolean;
  withInterviewers?: boolean;
  withInterviewerFullNames?: boolean;
  html?: boolean;
};

export const buildInterviewPanelString = ({
  sortedVisibleEvents,
  timezone,
  useTimeRanges = true,
  withInterviewers = false,
  withInterviewerFullNames = false,
  html = true,
}: BuildInterviewPanelProps): string => {
  const dateGroups = getEventDateGroups<PartialEvent>(sortedVisibleEvents);
  const LINE_OPEN = html ? "<span>" : "";
  const LINE_CLOSE = html ? "</span>" : "";
  const LINE_BREAK = html ? "<br>" : "\n";
  const MIDDOT = html ? "  &middot;" : "•";
  const SPACE = html ? "&nbsp;" : " ";

  let res = "";
  if (dateGroups.length) {
    res = dateGroups.reduce((acc, next, idx) => {
      const { startTime: dateGroupStartTime, events: dateGroupEvents } = next;

      let dayEventsStr = "";
      dateGroupEvents.forEach((eventOrBreak, eventIdx) => {
        if ("lengthMinutes" in eventOrBreak) {
          const breakTime = moment
            .duration(eventOrBreak.lengthMinutes, "minutes")
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            .format("h [hr] m [min]", {
              usePlural: false,
              trim: "all",
            });
          const lineBreak =
            eventIdx === dateGroupEvents.length - 1 ? "" : LINE_BREAK;
          dayEventsStr = `${dayEventsStr}${LINE_OPEN}${SPACE.repeat(
            6
          )}~ ${breakTime} break ~${LINE_CLOSE}${lineBreak}`;
          return;
        }
        const event = eventOrBreak;

        const sanitizedTitle = DOMPurify.sanitize(event.title, {
          ALLOWED_TAGS: [],
        });

        let interviewersStr = "";
        if (withInterviewers && event.interviewers.length) {
          interviewersStr = ` with ${_(event.interviewers)
            .map(({ firstName, lastName }) => {
              if (withInterviewerFullNames) {
                return joinNames(firstName, lastName);
              }
              return firstName;
            })
            .sort()
            .thru(joinWithFinalSeparator)
            .value()}`;
        }

        let interviewTime;
        if (useTimeRanges) {
          interviewTime = `${moment
            .tz(event.startTime, timezone)
            .format("h:mm")} - ${moment
            .tz(event.endTime, timezone)
            .format("h:mm a z")}: `;
        } else {
          interviewTime = `${moment
            .tz(event.startTime, timezone)
            .format("h:mm a z")} - `;
        }

        const lineBreak =
          eventIdx === dateGroupEvents.length - 1 ? "" : LINE_BREAK;
        dayEventsStr = `${dayEventsStr}${LINE_OPEN}${MIDDOT}${interviewTime}${sanitizedTitle}${interviewersStr}${LINE_CLOSE}${lineBreak}`;
      });
      const dayHeader = moment
        .tz(dateGroupStartTime, timezone)
        .format("ddd MMM Do");

      const lineBreak =
        idx === dateGroups.length - 1 ? "" : `${LINE_BREAK}${LINE_BREAK}`;
      const dayStr = `${LINE_OPEN}${dayHeader}${LINE_CLOSE}${LINE_BREAK}${dayEventsStr}${lineBreak}`;

      return `${acc}${dayStr}`;
    }, "");
  }

  return res;
};

export const getEventReplacements = (
  data: Partial<EventPlaceholderData>
): EventPlaceholderIDs => {
  const {
    interviewers,
    candidateFirstName,
    candidateLastName,
    candidateFullName,
    recruiterFirstName,
    recruiterLastName,
    recruiterFullName,
    coordinatorFirstName,
    coordinatorLastName,
    coordinatorFullName,
    companyName,
    jobRoleName,
    greenhouseJobUrl,
  } = data;

  const greenhouseJobLink =
    greenhouseJobUrl &&
    `<a href="${greenhouseJobUrl}" target="_blank" rel="noopener noreferrer">Job Description</a>`;
  const allInterviewersFirstNames = _(interviewers)
    .map("firstName")
    .sort()
    .thru(joinWithFinalSeparator)
    .value();
  const allInterviewersFullNames = _(interviewers)
    .map(({ firstName, lastName }) => joinNames(firstName, lastName))
    .sort()
    .thru(joinWithFinalSeparator)
    .value();
  const firstInterviewer = _(interviewers).sortBy("name").first();

  return {
    "company-name": companyName ?? "",
    "job-role-name": jobRoleName ?? "",
    "all-interviewers-full-names": allInterviewersFullNames ?? "",
    "all-interviewers-first-names": allInterviewersFirstNames ?? "",
    "candidate-first-name": candidateFirstName ?? "",
    "candidate-last-name": candidateLastName ?? "",
    "candidate-full-name": candidateFullName ?? "",
    "first-interviewer-first-name": firstInterviewer?.firstName ?? "",
    "first-interviewer-last-name": firstInterviewer?.lastName ?? "",
    "first-interviewer-full-name": joinNames(
      firstInterviewer?.firstName,
      firstInterviewer?.lastName
    ),
    "first-interviewer-title": firstInterviewer?.title ?? "",
    "recruiter-first-name": recruiterFirstName ?? "",
    "recruiter-last-name": recruiterLastName ?? "",
    "recruiter-full-name": recruiterFullName ?? "",
    "coordinator-first-name": coordinatorFirstName ?? "",
    "coordinator-last-name": coordinatorLastName ?? "",
    "coordinator-full-name": coordinatorFullName ?? "",
    "greenhouse-job-link": greenhouseJobLink ?? "",
  };
};

export const getStageReplacements = (
  data: Partial<StagePlaceholderData>
): StagePlaceholderIDs => {
  const replacedCustomVariables = renderCustomVariableReplacements(
    data.stageCustomVariables ?? {}
  );

  const greenhouseJobLink =
    data.greenhouseJobUrl &&
    `<a href="${data.greenhouseJobUrl}" target="_blank" rel="noopener noreferrer">Job Description</a>`;
  return {
    "candidate-first-name": data.candidateFirstName ?? "",
    "candidate-last-name": data.candidateLastName ?? "",
    "candidate-full-name": data.candidateFullName ?? "",
    "company-name": data.companyName ?? "",
    "job-role-name": data.jobRoleName ?? "",
    "recruiter-first-name": data.recruiterFirstName ?? "",
    "recruiter-last-name": data.recruiterLastName ?? "",
    "recruiter-full-name": data.recruiterFullName ?? "",
    "coordinator-first-name": data.coordinatorFirstName ?? "",
    "coordinator-last-name": data.coordinatorLastName ?? "",
    "coordinator-full-name": data.coordinatorFullName ?? "",
    "greenhouse-job-link": greenhouseJobLink ?? "",
    ...replacedCustomVariables,
  };
};

export const getEmailReplacements = (
  data: Partial<EmailPlaceholderData>
): EmailPlaceholderIDs => {
  const {
    allInterviewers,
    guideUrl,
    greenhouseJobUrl,
    sortedVisibleUpcomingEvents,
    timezone,
    stageCustomVariables,
  } = data;
  let nextInterviewDay = "";
  let nextInterviewTime = "";

  let upcomingInterviewTime = "";
  let upcomingInterviewDate = "";
  let remainingInterviewDatetimeRange = "";
  let remainingInterviewPanel = "";
  let remainingInterviewPanelWithInterviewers = "";
  let remainingInterviewPanelWithInterviewersFullNames = "";

  if (sortedVisibleUpcomingEvents?.length && timezone) {
    const nextEvent = _.first(sortedVisibleUpcomingEvents);
    const nextEventStartTime = moment.tz(nextEvent!.startTime, timezone);

    upcomingInterviewDate = nextEventStartTime.format("dddd, MMM Do");
    upcomingInterviewTime = nextEventStartTime.format("h:mm a z");
    const lastSameDayEvent = _.findLast<PartialEvent>(
      sortedVisibleUpcomingEvents,
      ({ startTime, endTime }) =>
        // We need to ensure we at least pick the next event
        startTime === nextEvent?.startTime ||
        moment.tz(endTime, timezone).isSame(nextEventStartTime, "day")
    );

    // Even if we have sorted visible events, we may not have an event for the same day,
    // based on the current timezone, which defaults to the user's timezone
    if (lastSameDayEvent) {
      const lastSameDayEventEndTime = moment.tz(
        lastSameDayEvent.endTime,
        timezone
      );
      nextInterviewDay = nextEventStartTime.format("dddd, MMM Do");
      nextInterviewTime = `${nextEventStartTime.format(
        "h:mm a"
      )} - ${lastSameDayEventEndTime.format("h:mm a z")}`;
      const lastEvent = _.last(sortedVisibleUpcomingEvents);
      const lastEventEndTime = moment.tz(lastEvent!.endTime, timezone);

      if (nextEventStartTime.isSame(lastEventEndTime, "day")) {
        remainingInterviewDatetimeRange = `${nextEventStartTime.format(
          "ddd M/D h:mm a"
        )} - ${lastEventEndTime.format("h:mm a z")}`;
      } else {
        remainingInterviewDatetimeRange = _(sortedVisibleUpcomingEvents)
          .groupBy((event) => {
            const localizedStartTime = moment.tz(event.startTime, timezone);
            const localizedEndTime = moment.tz(event.endTime, timezone);
            // If an event starts and ends on different days, create a new grouping
            // with the end time instead of the start time
            if (!localizedStartTime.isSame(localizedEndTime, "day")) {
              return moment.tz(event.endTime, timezone).startOf("day");
            }
            return moment.tz(event.startTime, timezone).startOf("day");
          })
          .mapValues((groupedEvents) => {
            const firstGroupedEventStartTime = moment.tz(
              _.first(groupedEvents)!.startTime,
              timezone
            );
            const lastGroupedEventEndTime = moment.tz(
              _.last(groupedEvents)!.endTime,
              timezone
            );
            // We need to handle the edge case where the last event crosses the day boundary
            // and when it does ALSO add the day to the format string for both times.
            const crossesDayBoundary = !firstGroupedEventStartTime.isSame(
              lastGroupedEventEndTime,
              "day"
            );
            return `${firstGroupedEventStartTime.format(
              "ddd M/D h:mm a"
            )} - ${lastGroupedEventEndTime.format(
              crossesDayBoundary ? "ddd M/D h:mm a z" : "h:mm a z"
            )}`;
          })
          .values()
          // Typescript isn't smart enough to use _ as placeholders
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          .thru(_.partial(joinWithFinalSeparator, _, _, " and "))
          .value();
      }

      remainingInterviewPanel = buildInterviewPanelString({
        sortedVisibleEvents: sortedVisibleUpcomingEvents,
        timezone,
        withInterviewers: false,
      });

      remainingInterviewPanelWithInterviewers = buildInterviewPanelString({
        sortedVisibleEvents: sortedVisibleUpcomingEvents,
        timezone,
        withInterviewers: true,
      });

      remainingInterviewPanelWithInterviewersFullNames =
        buildInterviewPanelString({
          sortedVisibleEvents: sortedVisibleUpcomingEvents,
          timezone,
          withInterviewers: true,
          withInterviewerFullNames: true,
        });
    }
  }

  const guideLink = `<a href="${guideUrl}" target="_blank" rel="noopener noreferrer">Interview Guide</a>`;
  const greenhouseJobLink = greenhouseJobUrl
    ? `<a href="${greenhouseJobUrl}" target="_blank" rel="noopener noreferrer">Job Description</a>`
    : "";
  const clickHereLink = `<a href="${guideUrl}" target="_blank" rel="noopener noreferrer">Click Here to Schedule Your Interview</a>`;
  const allInterviewersFirstNames = _(allInterviewers)
    .map("firstName")
    .sort()
    .thru(joinWithFinalSeparator)
    .value();
  const allInterviewersFullNames = _(allInterviewers)
    .map(({ firstName, lastName }) => joinNames(firstName, lastName))
    .sort()
    .thru(joinWithFinalSeparator)
    .value();
  const firstInterviewer = _(allInterviewers).sortBy("firstName").first();
  const firstInterviewerFullName = joinNames(
    firstInterviewer?.firstName,
    firstInterviewer?.lastName
  );

  const replacedCustomVariables = renderCustomVariableReplacements(
    stageCustomVariables ?? {}
  );

  return {
    "all-interviewers-first-names": allInterviewersFirstNames,
    "all-interviewers-full-names": allInterviewersFullNames,
    "first-interviewer-first-name": firstInterviewer?.firstName || "",
    "first-interviewer-last-name": firstInterviewer?.lastName || "",
    "first-interviewer-full-name": firstInterviewerFullName,
    "first-interviewer-title": firstInterviewer?.title || "",
    "company-name": data.companyName || "",
    "candidate-first-name": data.candidateFirstName || "",
    "candidate-last-name": data.candidateLastName || "",
    "candidate-full-name": data.candidateFullName || "",
    "scheduler-link": clickHereLink,
    "sender-name": data.senderName || "",
    "sender-first-name": data.senderFirstName || "",
    "job-role-name": data.jobRoleName || "",
    "greenhouse-job-link": greenhouseJobLink,
    "next-interview-date": nextInterviewDay,
    "next-interview-time": nextInterviewTime,
    "guide-link": guideLink,
    "upcoming-interview-date": upcomingInterviewDate,
    "upcoming-interview-time": upcomingInterviewTime,
    "remaining-interview-datetime-range": remainingInterviewDatetimeRange,
    "remaining-interview-panel": remainingInterviewPanel,
    "remaining-interview-panel-with-interviewers":
      remainingInterviewPanelWithInterviewers,
    "remaining-interview-panel-with-interviewers-full-names":
      remainingInterviewPanelWithInterviewersFullNames,
    "recruiter-first-name": data.recruiterFirstName || "",
    "recruiter-last-name": data.recruiterLastName || "",
    "recruiter-full-name": data.recruiterFullName || "",
    "coordinator-first-name": data.coordinatorFirstName || "",
    "coordinator-last-name": data.coordinatorLastName || "",
    "coordinator-full-name": data.coordinatorFullName || "",
    office: data.office || "",
    ...replacedCustomVariables,
  };
};
