import type { SerializedElementNode, SerializedLexicalNode } from "lexical";

import type { NodeRenderer } from "./types";

/** Creates a renderer for a content editor node. */
export function createNodeRenderer<
  Node extends SerializedLexicalNode | SerializedElementNode
>(nodeRenderer: NodeRenderer<Node>) {
  return nodeRenderer;
}

/** Creates a set of renderers for a content editor node. */
export function createNodeRenderers<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Renderers extends Record<string, NodeRenderer<any>>
>(nodeRenderers: Renderers) {
  return nodeRenderers;
}
