export const AllowedEmailTemplateSubjectTags = ["span"];
export const AllowedEmailTemplateBodyTags = [
  "span",
  "br",
  "a",
  "img",
  "div",
  "b",
  "i",
  "ul",
  "li",
  "ol",
];
export const AllowedInterviewTemplateTitleTags = ["span", "div"];
export const AllowedInterviewTemplateDescriptionTags = [
  "span",
  "br",
  "a",
  "img",
  "div",
  "b",
  "i",
  "ul",
  "li",
  "ol",
];
