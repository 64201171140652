/* eslint-disable no-underscore-dangle */
import type { SerializedListItemNode, SerializedListNode } from "@lexical/list";
import type {
  EditorThemeClasses,
  SerializedEditorState,
  SerializedElementNode,
  SerializedLexicalNode,
} from "lexical";
import pMap from "p-map";
import { cloneElement, isValidElement, ReactElement } from "react";

import { createId } from "../../../__utils/misc";
import { mergeThemes } from "../../__utils/misc";
import { theme as _theme } from "../../theme";
import type { NodeRendererContext, NodeRenderers } from "./types";

// create renderer
// ---------------

type RenderNodeContext = {
  nodeRendererContext: NodeRendererContext;
  fnContext: { renderId: string; keyCounter: number };
};

function isListNode(node: SerializedLexicalNode): node is SerializedListNode {
  return node.type === "list";
}

function isListItemNode(
  node: SerializedLexicalNode
): node is SerializedListItemNode {
  return node.type === "listitem";
}

async function renderNode(
  nodeRenderers: NodeRenderers,
  node: SerializedElementNode | SerializedLexicalNode,
  context: RenderNodeContext
): Promise<ReactElement | null> {
  const nodeRenderer = nodeRenderers[node.type];

  if (!nodeRenderer)
    throw new Error(`No renderer registered for node of type "${node.type}"`);

  const { fnContext } = context;

  const nodeRendererContext = { ...context.nodeRendererContext };

  // prepare list context
  if (isListNode(node)) {
    nodeRendererContext.list = { ...nodeRendererContext.list };
    const { list } = nodeRendererContext;
    list._mem = list._mem ?? { indexDriftByNesting: {} };
    const { _mem } = list;

    list.nesting = (list.nesting ?? -1) + 1;
    list.type = node.tag;
    list.start = node.start - 1; // turn into zero-based index

    _mem.indexDriftByNesting[list.nesting] = 0;
  }

  const childNodeRendererContext = { ...nodeRendererContext };

  async function renderChild(
    child: SerializedElementNode | SerializedLexicalNode,
    index = 0
  ) {
    childNodeRendererContext.childIndex = index;
    return renderNode(nodeRenderers, child, {
      ...context,
      nodeRendererContext: childNodeRendererContext,
    });
  }

  const isListOrListItem = isListNode(node) || isListItemNode(node);

  async function renderChildren() {
    if ("children" in node && node.children.length > 0)
      return node.children.length === 1
        ? renderChild(node.children[0])
        : pMap(
            node.children,
            renderChild,
            // for proper index drift computation, list and list items nodes must be rendered sequentially
            isListOrListItem ? { concurrency: 1 } : undefined
          );
    return undefined;
  }

  const children = await renderChildren();

  // prepare list item context
  if (isListItemNode(node)) {
    // Work around for lexical issue where it is possible to create a listitem without a parent
    //   Created Github issue: https://github.com/facebook/lexical/issues/3951
    if (
      !nodeRendererContext.list._mem ||
      nodeRendererContext.list.nesting == null
    ) {
      nodeRendererContext.list = { ...nodeRendererContext.list };
      const { list } = nodeRendererContext;
      list._mem = list._mem ?? { indexDriftByNesting: {} };
      const { _mem } = list;

      list.nesting = 0;
      list.type = "ul";
      list.start = 0;

      _mem.indexDriftByNesting[list.nesting] = 0;
    }

    const { list } = nodeRendererContext;

    // check if nested
    list.isListNestedItem =
      node.children.length === 1 && isListNode(node.children[0]);

    // update index drift
    const { _mem, nesting } = list;
    if (!_mem || nesting == null) {
      throw new Error("Missing list mem or nesting context");
    }
    if (list.isListNestedItem) _mem.indexDriftByNesting[nesting] += 1;
    list.indexDrift = _mem.indexDriftByNesting[nesting];
  }

  const nodeElement = await nodeRenderer(node, children, nodeRendererContext);

  fnContext.keyCounter += 1;

  return isValidElement(nodeElement)
    ? cloneElement(nodeElement, {
        key: `${fnContext.renderId}-${fnContext.keyCounter}`,
      })
    : nodeElement;
}

export type CreateRendererConfig = {
  nodeRenderers?: NodeRenderers;
  theme?: EditorThemeClasses;
  renderId?: string;
};

/** Creates a renderer for a content editor state. */
export function createRenderer({
  nodeRenderers,
  theme,
  renderId: globalRenderId,
}: CreateRendererConfig) {
  if (!nodeRenderers) throw new Error("No node renderers provided");
  return function renderer(state: SerializedEditorState, renderId?: string) {
    return renderNode(nodeRenderers, state.root, {
      fnContext: {
        renderId: renderId ?? globalRenderId ?? createId(),
        keyCounter: 0,
      },
      nodeRendererContext: {
        theme: mergeThemes([_theme, theme]),
        list: {},
      },
    });
  };
}
