import {
  createNameSet,
  createRendererSet,
  createSpecSet,
  createValueSet,
  Link,
  link,
  PersonName,
  personName,
  PersonNames,
  personNames,
  renderLink,
  renderPersonName,
  renderPersonNames,
  renderText,
  Text,
  text,
} from "@resource/atlas/content-editor/variables";
import { useMemo } from "react";

// set type
// --------

export type InterviewVariableSet = {
  atsRecruiterName: PersonName;
  atsCoordinatorName: PersonName;
  candidateName: PersonName;
  // candidatePhone: Text;
  jobTitle: Text;
  jobListingLink: Link;
  // guideLink: Link;
  companyName: Text;
  firstInterviewerName: PersonName;
  firstInterviewerJobTitle: Text;
  interviewerNames: PersonNames;
};

// renderer set
// ------------

export const interviewRendererSet = createRendererSet<InterviewVariableSet>({
  atsRecruiterName: renderPersonName,
  atsCoordinatorName: renderPersonName,
  candidateName: renderPersonName,
  jobTitle: renderText,
  jobListingLink: renderLink,
  companyName: renderText,
  firstInterviewerName: renderPersonName,
  firstInterviewerJobTitle: renderText,
  interviewerNames: renderPersonNames,
});

// name set
// --------

export const interviewNameSet = createNameSet<InterviewVariableSet>({
  atsRecruiterName: {
    name: "ats.recruiter.name",
    label: "Guide recruiter's full name",
  },
  atsCoordinatorName: {
    name: "ats.coordinator.name",
    label: "Coordinator's full name",
  },
  candidateName: {
    name: "candidate.name",
    label: "Candidate's full name",
  },
  jobTitle: {
    name: "job.title",
    label: "Job title",
  },
  jobListingLink: {
    name: "job.listing.link",
    label: "Job listing link",
  },
  companyName: {
    name: "company.name",
    label: "Company name",
  },
  firstInterviewerName: {
    name: "interviewer.name",
    label: "First interviewer name",
  },
  firstInterviewerJobTitle: {
    name: "interviewer.title",
    label: "First interviewer job title",
  },
  interviewerNames: {
    name: "interviewers.name",
    label: "Interviewer name",
  },
});

// spec set
// --------

export const interviewSpecSet = createSpecSet<InterviewVariableSet>(
  {
    atsRecruiterName: personName(),
    atsCoordinatorName: personName(),
    candidateName: personName(),
    jobTitle: text(),
    jobListingLink: link(),
    companyName: text(),
    firstInterviewerName: personName(),
    firstInterviewerJobTitle: text(),
    interviewerNames: personNames(),
  },
  { rendererSet: interviewRendererSet, nameSet: interviewNameSet }
);

export function useInterviewValueSet({
  replacementData,
}: {
  replacementData?: Record<string, unknown>;
}) {
  return useMemo(() => {
    if (!replacementData) return {};

    return createValueSet<InterviewVariableSet>({
      atsRecruiterName: {
        first: (replacementData["recruiter-first-name"] as string) ?? "",
        last: (replacementData["recruiter-last-name"] as string) ?? "",
      },
      atsCoordinatorName: {
        first: (replacementData["coordinator-first-name"] as string) ?? "",
        last: (replacementData["coordinator-last-name"] as string) ?? "",
      },
      candidateName: {
        first: (replacementData["candidate-first-name"] as string) ?? "",
        last: (replacementData["candidate-last-name"] as string) ?? "",
      },
      jobTitle: (replacementData["job-role-name"] as string) ?? "",
      jobListingLink: (replacementData["greenhouse-job-link"] as string) ?? "",
      companyName: (replacementData["company-name"] as string) ?? "",
      firstInterviewerName: {
        first:
          (replacementData["first-interviewer-first-name"] as string) ?? "",
        last: (replacementData["first-interviewer-last-name"] as string) ?? "",
      },
      firstInterviewerJobTitle: replacementData[
        "first-interviewer-title"
      ] as string,
      interviewerNames: {
        firstNames:
          (replacementData["all-interviewers-first-names"] as string) ?? "",
        fullNames:
          (replacementData["all-interviewers-full-names"] as string) ?? "",
      },
    });
  }, [replacementData]);
}
